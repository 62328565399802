import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import CookieConsent from 'react-cookie-consent'
import logo from './images/wunderland-logo-rgb-insider.png'

import { Container, Row, Col, Image, Button } from 'react-bootstrap'

import SignUp from './features/signup'
import Surveys from './features/survey/index'

function App() {
  const handleSignup = (person) => {
    console.log('signup')
  }
  const lang = 'de'
  return (
    <Router>
      <div className="App">
        <Container>
          <header className="App-header">
            <Row>
              <Col>
                <Row className="mb-3 mb-sm-4">
                  <Col
                    className="d-flex justify-content-center px-5 px-sm-1 pb-2 pb-sm-0 text-center"
                    sm={4}
                    md={3}
                    lg={2}
                  >
                    <Link to="/">
                      <Image
                        src={logo}
                        fluid
                        style={{ maxWidth: 140 + 'px' }}
                      />
                    </Link>
                  </Col>
                  <Col
                    sm={6}
                    md={8}
                    lg={9}
                    className="d-flex pl-1 pl-sm-4 pl-md-5 pt-1 pt-sm-2 pt-md-3 pt-lg-4 align-items-center"
                  >
                    <h1 className="display-5 text-primary fw-normal text-center text-sm-left">
                      Wunderland Insider
                    </h1>
                  </Col>
                </Row>
              </Col>
            </Row>
          </header>
          <Row>
            <Col>
              <Switch>
                <Route exact path="/">
                  <section>
                    <Row className="mt-3 gy-2">
                      <Col>
                        <h5 className="mb-3">Wir brauchen eure Hilfe!</h5>
                        <p>
                          Wahrscheinlich kennt jeder die Situation: Da tüftelt
                          man an etwas neuem und wird sich einfach nicht einig,
                          wie man es am Besten macht. Und das ist bei uns
                          genauso - gerade wenn es um große Projekte geht, bei
                          denen man auch finanziell mitunter große Risiken
                          eingeht.
                        </p>
                        <h5 className="mt-5 mb-3">
                          Deshalb möchten wir das Programm &bdquo;Wunderland
                          Insider&ldquo; ins Leben rufen.
                        </h5>
                        <p>
                          In unregelmäßigen Abständen werden wir die Wunderland
                          Insider per E-Mail um ihre Meinung zu neuen Ideen,
                          Artikeln und allerlei Spinnereien bitten. Und wir
                          werden uns für alle, die uns auf diese Weise helfen
                          besser zu werden, auch ein Dankeschön überlegen. Durch
                          die Teilnahme entstehen natürlich keinerlei Pflichten
                          oder Kosten.
                        </p>
                        <h5 className="mt-5 mb-3">
                          Für Mitarbeiter und alle Freunde des Wunderlandes.
                        </h5>
                        <p>
                          Da wir noch wenig Erfahrung mit so einem Programm
                          haben, wollen wir dieses zunächst nur einem kleinen
                          Kreis anbieten und erst danach vielleicht noch
                          erweitern. Deshalb ist die Anmeldung vorerst nur bis
                          28.02.2021 möglich.
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-5 mb-4">
                      <Col>
                        <LinkContainer to="/register">
                          <Button size="lg">
                            Jetzt Wunderland-Insider werden
                          </Button>
                        </LinkContainer>
                      </Col>
                    </Row>
                  </section>
                </Route>
                <Route path="/register">
                  <section>
                    <Row>
                      <Col>
                        <SignUp onSignup={handleSignup} />
                      </Col>
                    </Row>
                  </section>
                </Route>
                <Route path="/survey">
                  <Surveys />
                </Route>
                <Route path="/contact">
                  <section>
                    <Row>
                      <Col>
                        <h3>Kontakt</h3>
                        <p>Du hast Fragen, Probleme oder Feedback?</p>
                        <p>
                          Schreibe uns gern an{' '}
                          <a href="mailto:insider@miniatur-wunderland.de">
                            insider@miniatur-wunderland.de
                          </a>
                          .
                        </p>
                      </Col>
                    </Row>
                  </section>
                </Route>
              </Switch>
            </Col>
          </Row>
          <footer>
            <Row className="gy-1 mt-5 pt-5 mb-2 d-flex justify-content-center">
              <Col xs={12} sm="auto" className="text-center">
                <Link to="/contact">
                  <small>Kontakt</small>
                </Link>
              </Col>
              <Col xs={12} sm="auto" className="text-center">
                <a
                  href="https://www.miniatur-wunderland.de/service/datenschutz/"
                  target="_new"
                >
                  <small>Datenschutz</small>
                </a>
              </Col>
              <Col xs={12} sm="auto" className="text-center">
                <a
                  href="https://www.miniatur-wunderland.de/service/impressum/"
                  target="_new"
                >
                  <small>Impressum</small>
                </a>
              </Col>
            </Row>
            <Row className="gy-1 mt-2 mb-2 d-flex justify-content-center">
              <Col xs={12} sm="auto" className="text-center text-small">
                <small>&copy; 2021 Miniatur Wunderland GmbH</small>
              </Col>
            </Row>
          </footer>
        </Container>
      </div>
      <CookieConsent
        location="bottom"
        enableDeclineButton
        disableStyles
        declineButtonText={lang === 'de' ? 'Ablehnen' : 'Decline'}
        buttonText={lang === 'de' ? 'Akzeptieren' : 'Accept'}
        cookieName="mwi_cookies_accepted"
        style={{
          zIndex: 300,
          background: 'rgba(0, 0, 0, 0.8)',
          padding: 1 + 'em',
          paddingTop: 1.3 + 'em',
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: 100 + '%',
          textAlign: 'center',
          borderTop: '1px solid #00ffff',
        }}
        contentStyle={{
          display: 'inline-block',
          marginBottom: '1em',
          fontSize: '1rem',
          color: '#fff',
        }}
        buttonWrapperClasses="inline-block"
        buttonClasses="btn btn-primary"
        buttonStyle={{
          display: 'inline-block',
          marginLeft: '1em',
        }}
        declineButtonClasses="btn btn-secondary"
        declineButtonStyle={{
          display: 'inline-block',
          marginLeft: '1em',
        }}
        sameSite="lax"
        expires={150}
      >
        {lang === 'de' ? (
          <p>
            Diese Website verwendet Cookies zu Zwecken der Analyse der Benutzung
            und der Personalisierung. <br />
            <a
              href="https://www.miniatur-wunderland.de/service/datenschutz/"
              target="_new"
              style={{ color: '#fff', textDecoration: 'underline' }}
            >
              Weitere Informationen
            </a>
          </p>
        ) : (
          <p>
            This website uses cookies for analytical and personalization
            purposes.
          </p>
        )}
      </CookieConsent>
    </Router>
  )
}

export default App
