import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import SignupForm from './signup_form'
import axios from 'axios'

/* eslint-disable react/prop-types */
function SignUp({ onSignup, lang = 'de' }) {
  let { path, url } = useRouteMatch()
  let location = useLocation()
  let history = useHistory()
  const personData = React.useRef(null)
  const handleSubmit = (person) => {
    personData.current = person
    axios
      .post('https://insider.miwu.land/api/add/', { lang, ...person })
      .then((response) => {
        if (response.data.success) {
          if (response.data.contact) {
            personData.current = {
              smContactId: response.data.contact,
              ...personData.current,
            }
          }
          history.push(`${url}/confirmation`)
        }
      })
      .catch((error) => console.log(error))
  }

  React.useEffect(() => {
    if (personData.current && personData.current.smContactId) {
      if (onSignup) {
        onSignup(personData.current)
      }
    }
  })

  React.useEffect(() => {
    const query = new URLSearchParams(location.search)
    if (query.get('a') && query.get('a').length > 1) {
      console.log('confirm')
    }
  }, [location.search])

  return (
    <div id="signup">
      <Row className="mt-4 mb-4">
        <Col>
          <h3 className="text-primary">Registrierung</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Switch location={location}>
            <Route key="home" exact path={path}>
              <Redirect to={`${url}/form`}>Link</Redirect>
            </Route>
            <Route key="form" path={`${path}/form`}>
              <SignupForm onSubmit={handleSubmit} />
            </Route>
            <Route key="confirmation" path={`${path}/confirmation`}>
              <h3>Nur noch ein Schritt.</h3>
              <p>
                Du wirst innerhalb der nächsten 30 min. eine E-Mail bekommen, um
                die Anmeldung abzuschließen. Falls Du diese nicht erhältst,
                schau bitte auch im Spam-Ordner nach und füge die
                Absenderadresse zur Liste sicherer Sender hinzu.
              </p>
            </Route>
            <Route key="emailConfirmation" path={`${path}/confirm`}>
              <h3>Perfekt - willkommen im Team!</h3>
              <p>
                Du hast Dich erfolgreich registriert und wirst bald von uns
                hören.
              </p>
            </Route>
          </Switch>
        </Col>
      </Row>
    </div>
  )
}

export default SignUp
