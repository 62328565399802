import React from 'react'
import {
  Switch,
  Route,
  Link,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { Row, Col, Image, Button } from 'react-bootstrap'

function Surveys() {
  let location = useLocation()
  let { path, url } = useRouteMatch()

  return (
    <section>
      <Switch location={location}>
        <Route key="home" exact path={path}>
          <Row>
            <Col>
              <h3>Umfragen</h3>
              <p>
                Die Wunderland Insider Umfragen sind nicht öffentlich.
                Mitglieder erhalten den Zugang per E-Mail.
              </p>
              <p>
                Möchten Sie Wunderland Insider werden?{' '}
                <a href="/">Mehr Infos und Registrierung</a>
              </p>
            </Col>
          </Row>
        </Route>

        <Route key="book" path={`${path}/book`}>
          <Row>
            <Col>
              <iframe
                title="formBook"
                src="https://docs.google.com/forms/d/e/1FAIpQLScyFYyZF3blTrhqsZ6mlOfyrX7mMRnpX97A1s2yNAQVGgo5eA/viewform?embedded=true"
                width={window.innerWidth - 30}
                height="1467"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                onLoad={console.log('iF load')}
              >
                Loading…
              </iframe>
            </Col>
          </Row>
        </Route>
      </Switch>
    </section>
  )
}

export default Surveys
